<template>
  <div class="p-6 md:p-10">
    <div class="flex justify-between items-center mb-12 md:mb-2">
      <nuxt-link to="/">
        <img
          src="/images/flik-logo.png"
          alt="Flik Logo"
          class="object-contain h-7"
          height="30"
        />
      </nuxt-link>
      <NuxtLink to="/" class="text-gray-400 text-sm">
        Back to dashboard
      </NuxtLink>
    </div>

    <div class="flex flex-col items-center mb-10">
      <h1 class="text-gray-900 font-bold text-2xl text-center mb-2">
        Complete Your Onboarding
      </h1>
      <div class="text-center w-[360px]">
        <p class="text-gray-600 text-sm">
          To verify your account, we kindly request a few key details to
          optimize your account experience.
        </p>
      </div>
    </div>

    <div class="flex justify-center">
      <slot />
    </div>
  </div>
</template>
